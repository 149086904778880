export const RouterPathApp  = {
  HOME: {
    keyText: 'sidebar.menu.home',
    href: '/home',
    roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
    title: ''
  },
  DASHBOARD: {
    keyText: 'sidebar.menu.dashboard',
    href: '/dashboard',
    roles: ['ROLE_ADMIN'],
    title: ''
  },
  SCHOOL: {
    keyText: 'sidebar.menu.schools',
    href: '/schools',
    roles: ['ROLE_ADMIN'],
    title: ''
  },
  STUDENTS: {
    keyText: 'sidebar.menu.student.title',
    href: '/student',
    roles: ['ROLE_ADMIN'],
    title: '',
    items: [
      {
        keyText: 'sidebar.menu.student.student-list',
        href: '/student/list',
        roles: ['ROLE_ADMIN'],
        title: '',
      },
      {
        keyText: 'sidebar.menu.student.create-inclusion',
        href: '/student/create',
        roles: ['ROLE_ADMIN'],
        title: '',
      },
      {
        keyText: 'sidebar.menu.insurance.multimedia',
        href: '/insurance/multimedia',
        roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
        title: '',
      }
    ]
  },
  INSURANCE: {
    keyText: 'sidebar.menu.insurance.home',
    href: '/insurance',
    roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
    title: '',
    items: [
      {
        keyText: 'sidebar.menu.insurance.habitation',
        href: '/insurance/housing',
        roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
        title: '',
        value: "home",
      },
      {
        keyText: 'sidebar.menu.insurance.health-mobility',
        href: '/insurance/health-mobility',
        roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
        title: '',
        value: "health/mobility",
      },
      {
        keyText: 'sidebar.menu.insurance.multimedia',
        href: '/insurance/multimedia',
        roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
        title: '',
        value: "multimedia",
      },
      {
        keyText: 'sidebar.menu.insurance.health-mutual',
        href: '/insurance/health-mutual',
        roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
        title: '',
        value: "health-mutual",
      },
    ]
  },
  DOCS: {
    keyText: 'sidebar.menu.docs',
    href: '/documents',
    roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
    title: ''
  },
  CLAIMS: {
    keyText: 'sidebar.menu.claims.home',
    href: '/claims',
    roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
    title: '',
    items: [
      {
        keyText: 'sidebar.menu.claims.mine',
        href: '/claims/my-claims',
        roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
        title: '',
      },
      {
        keyText: 'sidebar.menu.claims.declare',
        href: '/claims/declare',
        roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
        title: '',
      }
    ]
  },
  CONTACT: {
    keyText: 'sidebar.menu.contact',
    href: '/contact',
    roles: ['ROLE_STUDENT_INCLUSIVE', 'ROLE_STUDENT_PARTNER', 'ROLE_STUDENT_CLASSIC'],
    title: ''
  }
}
