
export const CardLinksData = [
  {
    text: 't("app.home-card")',
    image: '/images/CHome.png',
    link: '/insurance/housing',
    color: '#2F6472',
    icon: 'home',
    displayForCS: 'block',
    newO: false,
    value: 'home'
  },
  {
    text: 't("app.health-mobility-card")',
    image: '/images/CHealthMobility.png',
    link: '/insurance/health-mobility',
    color: '#2F6472',
    icon: 'flight',
    displayForCS: 'none',
    newO: false,
    value:'health/mobility'

  },
  {
    text: 't("app.multimedia-card")',
    image: '/images/CMultimedia.png',
    link: '/insurance/multimedia',
    color: '#2F6472',
    icon: 'laptop',
    newO:  false,
    value: 'multimedia'
  },
  {
    text: 't("app.health-mutual-card")',
    image: '/images/CMutual.png',
    link: '/insurance/health-mutual',
    color: '#2F6472',
    icon: 'mutual',
    displayForCS: 'block',
    newO: false,
    value: 'mutual'
  }
];