import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/styles';
import {Grid, Typography, Link, Modal, Box} from "@material-ui/core";
import essentialM from "../../assets/images/essentielM.png";
import none from "../../assets/images/none.png";
import check from "../../assets/images/checked-green.png";
import allM from "../../assets/images/allM.png";
import { useTranslation } from "react-i18next";
import {getListPriceMultimediaInsurance, detailMultimediaSuccess} from "../../redux/actions/InsuranceActions";
import { useDispatch,useSelector } from 'react-redux';
import { CardLink, CardLinksData } from './components/Cards';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#2f6472',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "block",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  stepTitle: {
    textTransform: "uppercase",
    color: "#2F6472",
    fontWeight: "bold"
  },
  middleItem:{
    display: "flex",
  },
  paddingTop30: {
    paddingTop: "30px",
  },
  cardMultimedia: {
    cursor: "pointer",
    border: "solid 1px #2f6472",
    "&:hover":{
      mozTransform: "scale(1.06)",
      webkitTransform: "scale(1.02)",
      transform: "scale(1.02)",
      transition: "all 0.1s",
    }
  },
  cardMultimediaNew: {
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    "&:hover":{
      backgroundColor: "#0019e20f",
      mozTransform: "scale(1.06)",
      webkitTransform: "scale(1.02)",
      transform: "scale(1.02)",
      transition: "all 0.1s",
  
    }
  },
  cardMultimediaActive: {
    "& div":{
      background: "#F46036",
      border: "2px solid #F46036",
      "& h6":{
        color: "white",
      }
    }
  },
  cardMultimediaActiveNew: {
    backgroundColor: "#0019e20f",
  },
  contentCardMultimedia: {
    margin: "6px",
    padding: "10px",
  },

  priceCardMultimedia: {
    margin: "6px",
    padding: "10px",
    fontWeight: "600",
  },
  priceCardMultimediaNew: {
    fontWeight: "600",
  },
  img: {
    width: "100px",
    height: "100px",
  },
  imgChecked: {
    position: "absolute",
    top: "0",
    right: "10px",
    width: "20px",
  },
  titleCardMultimedia: {
    margin: "6px",
    padding: "10px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "12px",
  },
  titleCardMultimediaNew: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "12px",
  },
  categoryTitle : {
    color :'#000000c7',
    "& h4":{
      fontWeight: "600",
      wordBreak: "break-all",
    }
  },
  categoryContent: {
    display: 'inline-flex',
    marginTop: "10px",
    marginBottom: "10px",
    borderRadius: "0px",
    height: "113px",
    paddingTop: "3%!important",
    paddingBottom: "3%!important",
    overflowY: "auto",
    "& h6": {
      height: "auto",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
      height: "80px",
    },
  },
  categoryContentButton: {
    marginTop: "10px",
    marginBottom: "20px",
  },
  submit: {
    padding: "10px",
    background: "white",
    color: "#F46036",
    fontSize: "18px",
    fontWeight: "500",
    boxShadow: "none",
    "&:hover":{
      background: "#F46036",
      color: "white",
    },
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
      padding: "7px",
    },
  },
  colorDarkBlue: {
    background: "#2F6472",
    "& h6":{
      color: "white",
    },
    borderRight: "solid white 5px",
    borderLeft: "solid white 5px",
    [theme.breakpoints.between("xs", "sm")]: {
      borderRight: "solid white 0px",
      borderLeft: "solid white 0px",
    },
  },
  colorLightBlue: {
    background: "#8ECECE",
    "& h6":{
      color: "white",
    }
  }
}));

export const MultimediaInsuranceOption = ({interval, optionType = "Multimedia_Habitation"}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const {t} = useTranslation('common');
  const [typeOfferSelectionned,setTypeOfferSelectionned] = useState("multimedia");
  const [isOpen, setOpen] = useState(false);
  const listMultimediaInsurance = useSelector((state) => state.multimediaInsurance.data);
  const multimediaInsurance = useSelector((state) => state.multimediaInsurance.dataDetails);
  const [expanded, setExpanded] = useState('');

  const [withoutOffer, setWithoutOffer] = useState(false);


  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const onChangeMultimedia = async () =>{
    await dispatch(getListPriceMultimediaInsurance({ type: optionType, school: -999, recurring: interval}));
  }
  useEffect(() => {
    onChangeMultimedia();
  }, [interval]);

  useEffect(() => {
    dispatch(detailMultimediaSuccess({...listMultimediaInsurance[0], active: true}));
  }, [listMultimediaInsurance]);

  const offerSelected = (insuranceProduct) => {
    if(insuranceProduct !== ""){
      dispatch(detailMultimediaSuccess({...insuranceProduct, active: true}));
      setWithoutOffer(false);
    }else{
      dispatch(detailMultimediaSuccess({...insuranceProduct, active: false}));
      setWithoutOffer(true);
    }
  };


  return (
  <>
      <Grid container className={classes.padding30} style={{marginTop: "20px", marginBottom: "20px"}} spacing={2} justifyContent="center" alignItems="center">
          {listMultimediaInsurance.map((insuranceProduct) => {
            return (
          <Grid onClick={() => offerSelected(insuranceProduct) } item xs={12} sm={4} lg={3} className={multimediaInsurance.condition === insuranceProduct.condition ? classes.cardMultimediaActiveNew : ""} >
            <div className={[classes.cardMultimediaNew]}>
              <div>
                {multimediaInsurance.condition === insuranceProduct.condition && <img className={classes.imgChecked} src={check}></img>}
                {insuranceProduct.condition === "Essentiels hors tel" && <img className={classes.img} src={essentialM}></img>}
                {insuranceProduct.condition === "Tous produit hors tel" && <img className={classes.img} src={allM}></img>}
              </div>
              <Typography variant="h6" className={classes.priceCardMultimediaNew}>
                {(insuranceProduct.unit_amount / 100)}€ {interval === "month" ? "/mois" : "/année"} <br></br>
              </Typography>
              <Typography variant="h6" className={classes.textCardMultimediaNew}>
                {t('insurance.multimedia.WithoutLimit')}    
              </Typography>
              <Typography variant="h6" className={classes.titleCardMultimediaNew}>
                {insuranceProduct.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.title')}
                {insuranceProduct.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.title')}
              </Typography> 
            </div>
          </Grid>)
          })} 
          <Grid onClick={() => offerSelected("") } item xs={12} sm={4} lg={3} className={withoutOffer ? classes.cardMultimediaActiveNew : ""} >
            <div className={[classes.cardMultimediaNew]}>
              <div>
              {withoutOffer && <img className={classes.imgChecked} src={check}></img>}
                <img className={classes.img} src={none}></img>
              </div>
              <Typography variant="h6" className={classes.priceCardMultimediaNew}>
                0.00 {t('insurance.multimedia.€perMonth')} 
              </Typography><br/>
              <Typography variant="h6" className={classes.titleCardMultimediaNew}>
                {t('insurance.multimedia.withoutOffer')} 
              </Typography> 
            </div>
          </Grid> 
    </Grid>
    { multimediaInsurance && multimediaInsurance.active && <> 
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h4">
            {t('insurance.plus-information')}
          </Typography>       
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Grid container className={[classes.padding30, classes.categoryTitle]} spacing={2} alignItems="center">
              <Grid item align="left" justify="center" alignItems="left" xs={12}>
                <Typography variant="h4">
                  {t('insurance.multimedia.listFurniture')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={[classes.padding30, classes.noPaddingTop]} spacing={2} alignItems="center">
              <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={12} xs={12}>
                <Typography variant="h6">
                  {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.listFurniture')}
                  {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.listFurniture')}
                  {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.listFurniture')}
                  {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.listFurniture')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={[classes.padding30, classes.categoryTitle]} spacing={2} alignItems="center">
              <Grid item align="left" justify="center" alignItems="left" xs={12}>
                <Typography variant="h4">
                  {t('insurance.multimedia.guarantee')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.padding30} spacing={2} alignItems="center">
              <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
                <Typography variant="h6">
                  {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.guarantee1')}
                  {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.guarantee1')}
                  {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.guarantee1')}
                  {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.guarantee1')}
                </Typography>
              </Grid>
              <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
                <Typography variant="h6">
                {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.guarantee2')}
                  {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.guarantee2')}
                  {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.guarantee2')}
                  {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.guarantee2')}    
                </Typography>
              </Grid>
              <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
                <Typography variant="h6">
                  {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.guarantee3')}
                  {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.guarantee3')}
                  {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.guarantee3')}
                  {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.guarantee3')}    
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={[classes.padding30, classes.categoryTitle]} spacing={2} alignItems="center">
              <Grid item align="left" justify="center" alignItems="left" xs={12}>
                <Typography variant="h4">
                  {t('insurance.multimedia.condition')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.padding30} spacing={2} alignItems="center">
              <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
                <Typography variant="h6">
                  {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.condition1')}
                  {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.condition1')}
                  {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.condition1')}
                  {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.condition1')} 
                </Typography>
              </Grid>
              <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
                <Typography variant="h6">
                  {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.condition2')}
                  {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.condition2')}
                  {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.condition2')}
                  {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.condition2')}
                </Typography>
              </Grid>
              <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
                <Typography variant="h6">
                  {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.condition3')}
                  {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.condition3')}
                  {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.condition3')}
                  {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.condition3')}
                </Typography>
              </Grid>
            </Grid>
          </>
        </AccordionDetails>
      </Accordion>
      <Grid container className={[classes.noPaddingTop]} spacing={2} alignItems="center">
        <Grid item align="center" justify="center" alignItems="center" sm={4} xs={4}>
          <Link color="inherit"
          className={classes.dialogItemContent}
          style={{color: '#0E0DB4', fontSize: "13px", textAlign: "left"}}
          variant='h5'
          target="_blank"
          href={multimediaInsurance === null ? null : '/document/multimedia/notice_studcorp_m.pdf' }>
          {'notice studcorp.pdf'}
          </Link>
        </Grid>
        <Grid  item align="center" justify="center" alignItems="center" sm={4} xs={4}>
          <Link color="inherit"
          className={classes.dialogItemContent}
          style={{color: '#0E0DB4', fontSize: "13px", textAlign: "left"}}
          variant='h5'
          target="_blank"
          href={multimediaInsurance === null ? null : '/document/multimedia/ipid.pdf' }>
          {'fiche ipid.pdf'}
          </Link> 
        </Grid>
        <Grid  item align="center" justify="center" alignItems="center" sm={4} xs={4}>
          <Link color="inherit"
          className={classes.dialogItemContent}
          style={{color: '#0E0DB4', fontSize: "13px"}}
          variant='h5'
          target="_blank"
          href={multimediaInsurance === null ? null : '/document/multimedia/' + multimediaInsurance.fic}>
          {"fiche d'informations et de conseils.pdf"}
          </Link>
        </Grid>
      </Grid>
      </>
    }
  </>
  );
 };

 export default MultimediaInsuranceOption;